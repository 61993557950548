import { useLocation, useNavigate } from 'react-router-dom';

import { appInit } from '~/Actions/ActionApp';
import { useMount } from '~/hooks';
import { useAppDispatch } from '~/store';

const Init = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useMount(() => {
    dispatch(appInit(navigate, location));
  });

  return <></>;
};

export default Init;
