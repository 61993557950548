import ReactDOM from 'react-dom/client';

import initCommonMenu from '~/commonMenu';
import Root from '~/root';

import { initScroll } from './scroll';

import '@wg/wows-common-footer/style.css';

const renderRoot = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);
renderRoot.render(<Root />);

try {
  initCommonMenu();
} catch (e: unknown) {
  console.error('Failed to init common menu', e);
}

try {
  initScroll();
} catch (e: unknown) {}
