import { createFooter, getLegalLinksFallback, getLegalLinks, LegalLinkNames, initDictionaryCatalog } from '@wg/wows-common-footer/main';
import { IFooterProps, Realm } from '@wg/wows-common-footer/types';
import { useEffect } from 'react';
import styles from './Footer.scss';
import preloaded from '~/preloaded';

const Footer = () => {
    useEffect(() => {
        async function initFooter() {
            await initDictionaryCatalog({ language: preloaded.settings.languageCode, dictionaryCatalog: window.i18n.catalog });

            const linksConfig = getLegalLinks({ language: preloaded.settings.languageCode, realm: preloaded.settings.realm as Realm });
            delete linksConfig[LegalLinkNames.PARTNERS];
            const legalLinks = getLegalLinksFallback({ language: preloaded.settings.languageCode, realm: preloaded.settings.realm as Realm, linksConfig });

            const options: IFooterProps = {
                selector: '#footer-container',
                language: preloaded.settings.languageCode,
                realm: preloaded.settings.realm as Realm,
                version: '',
                dictionaryCatalog: window.i18n.catalog,
                rootUrl: null,
                legalLinks,
                onLanguageChange: ({ realm, language }) => {
                    location.href = `${preloaded.settings.realmsMap[realm]}/?lang=${language}`;
                },
                onClickByLink: (event: MouseEvent) => {
                    event.preventDefault();
                    const target = event.currentTarget as HTMLLinkElement;
                    if (target.id !== 'ot-sdk-btn') {
                        window.open(target.href, '_blank');
                    }
                },
            };

            if (preloaded.settings.realm === 'cn360') {
                options.realms = [];
            }

            createFooter(options);

            if (window.OneTrust) {
                window.OneTrust.initializeCookiePolicyHtml();
            }
        }

        initFooter();
    }, []);

    return <div id={'footer-container'} className={styles.footer} />;
}

export default Footer;